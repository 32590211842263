import { getIntlDate } from './general';

const moment = require('moment-timezone');
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const en = require('dayjs/locale/en-gb');
const isoWeek = require('dayjs/plugin/isoWeek');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
const advancedFormat = require('dayjs/plugin/advancedFormat');

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.locale({
    ...en,
    weekStart: 1,
});

moment.updateLocale('en', {
    week: {
        dow: 1, // Monday is the first day of the week.
    },
});

export const nth = (d: number) => {
    if (d > 3 && d < 21) return 'th';

    switch (d % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

export const sortByLatest = (dateA, dateB) => {
    if (dayjs(dateA).isBefore(dateB)) {
        return 1;
    } else if (dayjs(dateB).isBefore(dateA)) {
        return -1;
    }

    return 0;
};

// Below methods may not be needed with UTC

export const startOfWeek = (timeZone) => {
    // here we are taking date based on user timezone
    const newDate = getIntlDate(new Date(), timeZone);
    const startOfWeek = dayjs(newDate).startOf('week');
    return startOfWeek;
};

function stdTimezoneOffset(day) {
    var jan = day.set('month', 0).set('day', 1);
    var jul = day.set('month', 6).set('day', 1);
    return Math.max(jan.toDate().getTimezoneOffset(), jul.toDate().getTimezoneOffset());
}

function isDstObserved(day) {
    return day.toDate().getTimezoneOffset() < stdTimezoneOffset(day);
}

export function getWeek(day) {
    const startOfYear = day.startOf('y');
    const endOfFirstWeek = startOfYear.endOf('w').add(1, 'ms');
    const updatedDay = isDstObserved(day) ? day.add(1, 'hour') : day;

    if (
        updatedDay.isSame(startOfYear) ||
        updatedDay.isSame(endOfFirstWeek) ||
        updatedDay.isAfter(startOfYear) & updatedDay.isBefore(endOfFirstWeek)
    ) {
        return 1;
    }

    const diffInWeek = updatedDay.diff(endOfFirstWeek, 'd');

    return Math.ceil(diffInWeek / 7) + 1;
}

export function getWeekNumber(since, date) {
    const firstPlanActiveFromUtc = moment.utc(since).startOf('isoWeek');
    const targetDate = moment(date).endOf('isoWeek');

    const weeks = targetDate.utc(true).endOf('isoWeek').diff(firstPlanActiveFromUtc, 'w');
    return weeks + 1;
}

export default dayjs;
