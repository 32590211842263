import React, { Component } from 'react';
import Router from 'next/router';
import * as Sentry from '@sentry/browser';

import loadAuth from '../apis/auth';
import loadDatabase from '../apis/database';
import loadFunction from '../apis/functions';
import { getUserData$ } from '../apis/user';
import { Subscription } from 'rxjs';
import { User } from '../interfaces/user';
import { convertParamsToObject } from '../helpers/general';

let database = null;
let auth = null;
let functions = null;

export const UserContext = React.createContext(null);

const defaultUserState: User = {
    loggedIn: false,
    user: null,
    createdAt: null,
    profile: {
        name: null,
        email: null,
        country: null,
        gender: null,
    },
    preferences: {},
    nutritionalInfo: {},
    planMilestones: {
        planReleased: null,
        planReviewed: null,
        questionnaireComplete: null,
        week17ModalShown: false,
        welcomeModalShown: false,
    },
    other: {},
    currentPlan: {},
    currentWeek: 0,
    plans: {},
    compliancyData: {},
    roles: {},
    isLoaded: false,
    logout: () => {},
    claims: {
        impersonator: undefined
    },
    review: undefined,
    openInNutritionAppLink: '',
    showOpenInNutritionAppBanner: false
};

export default class UserProvider extends Component<{}, User> {
    userListener?: Subscription;

    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
        this.getUserAdditionalData = this.getUserAdditionalData.bind(this);
        this.cleanUpListeners = this.cleanUpListeners.bind(this);
        this.userListener = null;

        this.state = {
            ...defaultUserState,
        };
    }

    async componentDidMount() {
        if (!auth) {
            auth = await loadAuth();
        }
        if (!database) {
            database = await loadDatabase();
        }

        if (!database) {
            database = await loadDatabase();
        }

        if (!functions) {
            functions = await loadFunction();
        }

        await auth.onAuthStateChanged(async user => {
            const { route } = Router;

            if (user) {
                const idTokenResult = await auth.currentUser.getIdTokenResult();
                const { uid, displayName, email } = user;
                this.setState({
                    loggedIn: true,
                    user,
                    claims: idTokenResult.claims
                });

                Sentry.configureScope(scope => {
                    scope.setUser({
                        id: uid,
                        username: displayName,
                        email,
                    });
                });

                await this.getUserAdditionalData(uid);

                if (route.includes('/login')) {
                    const { redirect_uri } = convertParamsToObject(window.location);

                    if (redirect_uri) {
                        Router.push(redirect_uri);
                    } else {
                        Router.push('/dashboard');
                    }
                }
            } else {
                this.cleanUpListeners();
                this.setState({
                    ...defaultUserState,
                });

                if (
                    !route.includes('/complete-signup') &&
                    !route.includes('/request-password-reset') &&
                    !route.includes('/login')
                ) {
                    Router.push(`/login?redirect_uri=${window.location.pathname}`);
                }
            }
        });
    }

    componentWillUnmount() {
        this.cleanUpListeners();
    }

    async getUserAdditionalData(userId) {
        if (this.userListener) {
            return;
        }

        this.userListener = getUserData$(userId).subscribe(async userData => {
            const { currentPlan, elevatePlan } = userData;
            
            if (currentPlan) {
                const dynamicLinkParams = await database.collection('deeplink').doc('data').get();
                const nutritionAppLink = dynamicLinkParams?.data()?.openInAppLink;
                const showOpenInNutritionAppBanner =
                    !!nutritionAppLink && currentPlan.productType === 'nutrition';
                if (!this.state.isLoaded) {
                    database
                        .collection('users')
                        .doc(userId)
                        .set({ other: { lastLoggedIn: new Date() } }, { merge: true });

                    functions.httpsCallable('logSession')({}).catch(error => {
                        console.log(error);
                    });
                }
                this.setState({
                    ...userData,
                    openInNutritionAppLink: nutritionAppLink,
                    showOpenInNutritionAppBanner,
                    isLoaded: true
                });
            } else if(elevatePlan && !currentPlan) {
                this.cleanUpListeners();
                await auth.signOut();
                Router.push('/login?elevateUser=true');
            } else {
                this.cleanUpListeners();
                await auth.signOut();
                Router.push('/login?accountExpired=true');
            }
        });
    }

    cleanUpListeners() {
        if (this.userListener) {
            this.userListener.unsubscribe();
            this.userListener = null;
        }
    }

    // eslint-disable-next-line class-methods-use-this
    async logout() {
        try {
            this.cleanUpListeners();
            await auth.signOut();
            Router.push('/login');
        } catch (error) {
            console.log('Error when trying to logout', error);
        }
    }

    hideOpenInNutritionAppBanner = () => {
        this.setState({
            showOpenInNutritionAppBanner: false,
        });
    };

    render() {
        const { children } = this.props;

        return (
            <UserContext.Provider
                value={{
                    ...this.state,
                    logout: this.logout,
                    hideOpenInNutritionAppBanner: this.hideOpenInNutritionAppBanner,
                }}
            >
                {children}
            </UserContext.Provider>
        );
    }
}
