const configs = {
    qa: {
        apiKey: "AIzaSyCtvdGf3XuA1jTwp4H7G--6VqtP0EcTJN8",
        authDomain: "j-clarke-fitness-backup.firebaseapp.com",
        databaseURL: "https://j-clarke-fitness-backup.firebaseio.com",
        projectId: "j-clarke-fitness-backup",
        storageBucket: "j-clarke-fitness-backup.appspot.com",
        messagingSenderId: "344511529342",
        appId: "1:344511529342:web:e97d201003f7e3314cf32f",
    },
    prod: {
        apiKey: "AIzaSyDj6crU8vEfJs6bxPVomQ3VWWKnmw6eupE",
        authDomain: "jclarkefitness-97456.firebaseapp.com",
        databaseURL: "https://jclarkefitness-97456.firebaseio.com",
        projectId: "jclarkefitness-97456",
        storageBucket: "jclarkefitness-97456.appspot.com",
        messagingSenderId: "427653557012",
        appId: "1:427653557012:web:c8784940ecb8215069b001"
    }
}

const config = configs[process.env.STAGE];

export const firebaseConfig = config;
