import { capitalizeString } from './general';
import dayjs from '../helpers/date-helpers';
import { Plans, Plan } from '../interfaces/user';

export const getCurrentPlan = (
    userPlans
): { currentPlan: Plan; currentWeek: number; plans: Plans } => {
    const plans = {};
    let currentPlan = null;
    let currentPlanWhenNotInActiveDates = null;
    let currentWeek = 0;

    userPlans.forEach(plan => {
        const planData = {
            ...plan.data(),
            planId: plan.id,
        };
        plans[plan.id] = planData;

        const { activeFrom, activeTo } = planData;
        if (dayjs.utc().isBetween(activeFrom.toDate(), activeTo.toDate(), 'date', '[]')) {
            currentPlan = planData;
        }

        if (dayjs().isSameOrBefore(activeFrom.toDate(), 'date')) {
            currentPlanWhenNotInActiveDates = planData;
        }
    });

    if(userPlans.docs.length) {
        const firstPlan = userPlans.docs[0].data();
        const { activeFrom } = firstPlan;
        const diff = dayjs().diff(activeFrom.toDate(), 'w');
        currentWeek = diff + 1;
    }

    return {
        currentPlan: !currentPlan ? currentPlanWhenNotInActiveDates : currentPlan,
        currentWeek,
        plans,
    };
};

export const checkIsPlanActive = (plan: Plan): boolean => {
    const { activeFrom, activeTo } = plan;

    if (!activeFrom || !activeTo) return false;

    return dayjs.utc().isBetween(activeFrom.toDate(), activeTo.toDate(), 'date', '[]');
};

export const getPlanDirectlyBefore = (plans: Plans, startOfCurrentPlan): Plan => {
    let planDirectlyBefore = null;
    const dayBeforeCurrentPlanStarted = startOfCurrentPlan.subtract(1, 'day');
    Object.keys(plans).some(planKey => {
        const plan = plans[planKey];
        const planActiveTo = dayjs(plan.activeTo.toDate());

        if (planActiveTo.utc().isSame(dayBeforeCurrentPlanStarted, 'date')) {
            planDirectlyBefore = plan;
            return true;
        }

        return false;
    });

    return planDirectlyBefore;
};

export const getPlanDirectlyAfter = (plans: Plans, endOfCurrentPlan): Plan => {
    let planDirectlyAfter = null;
    const dayAfterCurrentPlanEnds = endOfCurrentPlan.add(1, 'day');

    Object.keys(plans).some(planKey => {
        const plan = plans[planKey];
        const planActiveFrom = dayjs(plan.activeFrom.toDate());

        if (planActiveFrom.utc().isSame(dayAfterCurrentPlanEnds, 'date')) {
            planDirectlyAfter = plan;
            return true;
        }

        return false;
    });

    return planDirectlyAfter;
};

export const getPreviousPlan = (plans: Plans, currentPlanId: string): Plan => {
    const planKeys = Object.keys(plans);
    const currentPlanIndex = planKeys.indexOf(currentPlanId);

    return currentPlanIndex <= 0 ? null : plans[planKeys[currentPlanIndex - 1]];
};

export const getPreviousPlanWithWorkoutPreferences = (
    plans: Plans,
    currentPlanId: string
): Plan => {
    const currentPlan = plans[currentPlanId];

    if (currentPlan.workoutGroup !== undefined) {
        return currentPlan;
    } else {
        const reversedPlanKeys = Object.keys(plans).reverse();
        const matchingPlanKey = reversedPlanKeys.find(
            planKey => plans[planKey].workoutGroup !== undefined
        );
        return matchingPlanKey ? plans[matchingPlanKey] : null;
    }
};

export const getFirstPlanActiveFrom = (plans: Plans) =>
    Object.keys(plans).length ? plans[Object.keys(plans)[0]].activeFrom : undefined;

export const getNumberOfActivatedPlans = (plans: Plans): number =>
    Object.keys(plans).filter(planKey => {
        const plan = plans[planKey];
        const planActiveFromDate = plan.activeFrom.toDate();

        return dayjs(planActiveFromDate).isBefore(dayjs(), 'date');
    }).length;

export const orderedTableKeys = ['upper', 'push', 'lower', 'pull', 'full', 'legs'];

export function transformWorkoutMapToArray(workoutPlan) {
    const newData = [];
    orderedTableKeys.forEach(key => {
        const data = workoutPlan[key];
        if (data) {
            newData.push({
                id: key,
                group: key,
                name: capitalizeString(key),
                exercises: data,
            });
        }
    });
    return newData;
}