import { from } from 'rxjs';
import loadFirebase from '../firebase';
import { firebaseConfig } from '../config';

let database = null;

const loadDatabase = async () => {
    const firebase = await loadFirebase();
    await import('firebase/firestore');

    try {
        firebase.initializeApp(firebaseConfig);
        await firebase.firestore().enablePersistence();
    } catch (err) {
        // we skip the "already exists" message which is
        // not an actual error when we're hot-reloading
        if (!/already exists/.test(err.message)) {
            console.error('Firebase initialization error', err.stack);
        }
    }

    const db = firebase.firestore();
    try {
        // only connect to local firebase emulator if FB_EMULATOR is set to true
        if (process.env.FB_EMULATOR === 'true') {
            db.useEmulator('localhost', 8080);
        }
    } catch (error) {}

    return db;
};

export const getDatabase = async () => {
    if (!database) {
        database = await loadDatabase();
    }

    return database;
};

export const getDatabase$ = from(
    new Promise(async (resolve, reject) => {
        if (!database) {
            database = await loadDatabase();
        }

        resolve(database);
    })
);

export default loadDatabase;
