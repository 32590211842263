import { Observable } from 'rxjs';
import { QuerySnapshot } from '@google-cloud/firestore';
import firebase from 'firebase/app';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { getDatabase, getDatabase$ } from './database';
import dayjs from '../helpers/date-helpers';
import { getCurrentPlan } from '../helpers/plan-helpers';

dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const uuid = () => (Math.random() * Math.random()).toString(36).substr(2);

export const getUserData = async userId => {
    const database = await getDatabase();

    return database.collection('users').doc(userId);
};

export const getUserData$ = userId =>
    Observable.create(observer => {
        const initiallyResponded = [false, false, false, false];
        let userListener;
        let userRolesListener;
        let userPlansListener;
        let userCompliantLogsListener;
        let userProductsListener;

        let userData = {
            profile: {},
            preferences: {},
            questionnaireResults: {},
            planMilestones: {},
            currentPlan: {},
            plans: {},
            compliancyData: {},
            roles: {},
            other: {},
            products: {},
        };

        const returnData = () => {
            if (!initiallyResponded.includes(false)) {
                observer.next(userData);
            }
        };

        getDatabase$.subscribe(database => {
            const userRef = database.collection('users').doc(userId);

            userListener = userRef.onSnapshot(user => {
                if (user.exists) {
                    const data = user.data();
                    const currentWeek = data.currentPlan ? data.currentPlan.week : 0;
                    delete data.currentPlan;
                    userData = {
                        currentWeek,
                        ...userData,
                        ...data,
                    };
                }

                initiallyResponded[0] = true;
                returnData();
            });

            userRolesListener = userRef
                .collection('meta')
                .doc('roles')
                .onSnapshot(userRoles => {
                    if (userRoles.exists) {
                        userData = {
                            ...userData,
                            roles: { ...userRoles.data() },
                        };
                    }

                    initiallyResponded[1] = true;
                    returnData();
                });

            userPlansListener = userRef.collection('plans').onSnapshot(userPlans => {
                const { plans, currentPlan } = getCurrentPlan(userPlans);
                userData = {
                    ...userData,
                    plans,
                    currentPlan,
                };

                initiallyResponded[2] = true;
                returnData();
            });

            userCompliantLogsListener = userRef
                .collection('meta')
                .doc('compliant-log')
                .onSnapshot(compliantLogDoc => {
                    if (compliantLogDoc.exists) {
                        userData.compliancyData = {
                            ...userData.compliancyData,
                            ...compliantLogDoc.data(),
                        };
                    }

                    initiallyResponded[3] = true;
                    returnData();
                });
        });

        return () => {
            userListener();
            userRolesListener();
            userPlansListener();
            userCompliantLogsListener();
        };
    });

export const getUserCompliantLogs = async userId => {
    const database = await getDatabase();

    return database
        .collection('users')
        .doc(userId)
        .collection('meta')
        .doc('compliant-log');
};

export const getUserRoles = async userId => {
    const database = await getDatabase();

    return database
        .collection('users')
        .doc(userId)
        .collection('meta')
        .doc('roles');
};

export const getUserPlans = async userId => {
    const database = await getDatabase();

    return database
        .collection('users')
        .doc(userId)
        .collection('plans');
};

export const getWorkoutCustomisations = async (userId, planId) => {
    const database = await getDatabase();

    const currentPlan = await database
        .collection('users')
        .doc(userId)
        .collection('plans')
        .doc(planId)
        .get();

    return currentPlan.exists
        ? currentPlan.data().workouts.map(i => ({
              ...i,
              exercises: i.exercises.map(e => ({
                  ...e,
                  id: e.id || uuid(),
              })),
          }))
        : [];
};

export const getInfoForNutritionalPlan = async userId => {
    const database = await getDatabase();

    const currentPlan = await database
        .collection('users')
        .doc(userId)
        .collection('meta')
        .doc('infoForNutritionalPlan')
        .get();

    return currentPlan.exists ? currentPlan.data() : {};
};

export const getExerciseOverrides = async userId => {
    const database = await getDatabase();
    const overrides = await database
        .collection('users')
        .doc(userId)
        .collection('meta')
        .doc('overrides')
        .get()
        .then(s => s.data());

    if (overrides) {
        return overrides.workouts || [];
    }
    return [];
};

export const setInfoForNutritionalPlan = async (userId, updates) => {
    const database = await getDatabase();

    return await database
        .collection('users')
        .doc(userId)
        .collection('meta')
        .doc('infoForNutritionalPlan')
        .set(updates, { merge: true });
};

export const getExercisePreferences = async (userId, planId) => {
    const database = await getDatabase();

    const exercisePreferences = await database
        .collection('users')
        .doc(userId)
        .collection('exercise-preferences')
        .doc(planId)
        .get();

    return exercisePreferences.exists ? exercisePreferences.data().preferences : [];
};

export const updateExercisePreferences = async (userId, planId, newPreferences) => {
    const database = await getDatabase();

    return database
        .collection('users')
        .doc(userId)
        .collection('exercise-preferences')
        .doc(planId)
        .set({ preferences: newPreferences });
};

export const createNewWorkoutLog = async (userId, planId, workout, logType) => {
    const database = await getDatabase();

    const initialLog = {
        type: logType,
        workoutName: workout.name,
        workoutId: workout.id,
        workoutGroup: workout.group,
        exercises: {},
    };

    const docRef = await database
        .collection('users')
        .doc(userId)
        .collection('plans')
        .doc(planId)
        .collection('training-log')
        .add(initialLog);

    return { id: docRef.id, log: initialLog };
};

export const updateWorkoutLog = async (userId, planId, docId, logData) => {
    const database = await getDatabase();

    return await database
        .collection('users')
        .doc(userId)
        .collection('plans')
        .doc(planId)
        .collection('training-log')
        .doc(docId)
        .set(logData, { merge: false });
};

export const deleteWorkoutLog = async (userId, planId, docId) => {
    const database = await getDatabase();

    return await database
        .collection('users')
        .doc(userId)
        .collection('plans')
        .doc(planId)
        .collection('training-log')
        .doc(docId)
        .delete();
};

export const getWorkoutLog = async (userId, planId, logId) => {
    const database = await getDatabase();

    return database
        .collection('users')
        .doc(userId)
        .collection('plans')
        .doc(planId)
        .collection('training-log')
        .doc(logId)
        .get();
};

export const getUserWeightLogs = userId =>
    Observable.create(observer => {
        let weightLogListener;

        getDatabase$.subscribe(database => {
            weightLogListener = database
                .collection('users')
                .doc(userId)
                .collection('meta')
                .doc('weight-log')
                .onSnapshot(weightLogs => {
                    if (!weightLogs.exists) {
                        observer.next({});
                    } else {
                        observer.next(weightLogs.data());
                    }
                });
        });

        return () => weightLogListener();
    });

export const subscribeStepsLog = (userId, start, end) =>
    Observable.create(observer => {
        let listener = () => false;
        getDatabase$.subscribe(db => {
            if (!start || !end) {
                observer.next([]);
                return;
            }
            listener = db
                .collection('users')
                .doc(userId)
                .collection('meta')
                .doc('steps-log')
                .collection('logs')
                .where(firebase.firestore.FieldPath.documentId(), '>=', start)
                .where(firebase.firestore.FieldPath.documentId(), '<=', end)
                .onSnapshot(
                    snapshots => {
                        const docs = [];
                        snapshots.forEach(s => {
                            const doc = s.data();
                            docs.push({
                                id: s.id,
                                steps: doc.steps,
                            });
                        });
                        observer.next(docs);
                    },
                    e => console.log(e)
                );
        });

        return () => listener();
    });

export const subscribeCardioLog = (userId, start, end) =>
    Observable.create(observer => {
        let listener = () => false;
        getDatabase$.subscribe(db => {
            if (!start || !end) {
                observer.next([]);
                return;
            }
            listener = db
                .collection('users')
                .doc(userId)
                .collection('meta')
                .doc('cardio-log')
                .collection('logs')
                .where('date', '>=', start)
                .where('date', '<=', end)
                .onSnapshot(snapshots => {
                    const docs = [];
                    snapshots.forEach(s => {
                        const doc = s.data();
                        docs.push({
                            id: s.id,
                            ...doc,
                        });
                    });
                    observer.next(docs);
                });
        });

        return () => listener();
    });

export const listenToInfoForNutritionalPlan = userId =>
    Observable.create(observer => {
        let nutritionInfoListener;

        getDatabase$.subscribe(database => {
            nutritionInfoListener = database
                .collection('users')
                .doc(userId)
                .collection('meta')
                .doc('infoForNutritionalPlan')
                .onSnapshot(nutritionInfo => {
                    if (!nutritionInfo.exists) {
                        observer.next({});
                    } else {
                        observer.next(nutritionInfo.data());
                    }
                });
        });

        return () => nutritionInfoListener();
    });

export const setUserWeightLog = async (userId, key, value) => {
    const database = await getDatabase();

    return database
        .collection('users')
        .doc(userId)
        .collection('meta')
        .doc('weight-log')
        .set(
            {
                [key]: value,
            },
            { merge: true }
        );
};

export const getUserTrainingDiary = (userId: string) =>
    Observable.create(observer => {
        let trainingDiaryListener;

        getDatabase$.subscribe(database => {
            trainingDiaryListener = database
                .collection('users')
                .doc(userId)
                .collection('meta')
                .doc('training-diary')
                .onSnapshot(trainingDiary => {
                    if (!trainingDiary.exists) {
                        observer.next({});
                    } else {
                        observer.next(trainingDiary.data());
                    }
                });
        });

        return () => trainingDiaryListener();
    });

export const getUserWorkoutHistorySummary = (userId: string) =>
    Observable.create(observer => {
        let workoutHistorySummaryListener;

        getDatabase$.subscribe(database => {
            workoutHistorySummaryListener = database
                .collection('users')
                .doc(userId)
                .collection('meta')
                .doc('workout-history-summary')
                .onSnapshot(workoutHistorySummary => {
                    if (!workoutHistorySummary.exists) {
                        observer.next({
                            workouts: 0,
                            timeSpent: 0,
                            setsCompleted: 0,
                            repsCompleted: 0,
                        });
                    } else {
                        observer.next(workoutHistorySummary.data());
                    }
                });
        });

        return () => workoutHistorySummaryListener();
    });

export const getUserPayments = userId =>
    Observable.create(observer => {
        let paymentsListener;

        getDatabase$.subscribe(database => {
            paymentsListener = database
                .collection('users')
                .doc(userId)
                .collection('payments')
                .onSnapshot(snapshot => {
                    const paymentUpdates: { [id: string]: any } = {};

                    snapshot.docChanges().forEach(change => {
                        const { doc } = change;
                        paymentUpdates[doc.id] = doc.data();
                    });

                    observer.next(paymentUpdates);
                });
        });

        return () => paymentsListener();
    });

export const getUserList$ = Observable.create((observer: any) => {
    let userListListener: () => void;

    getDatabase$.subscribe(database => {
        const usersQuery = database.collection('users');

        userListListener = usersQuery.onSnapshot((snapshot: QuerySnapshot) => {
            const userUpdates: { [id: string]: any } = {};

            snapshot.docChanges().forEach(change => {
                const { doc } = change;
                const data = doc.data();
                data.user = {
                    uid: doc.id,
                }
                userUpdates[doc.id] = data;
            });

            observer.next(userUpdates);
        });
    });

    return () => userListListener();
});

export const updatePlanMilestoneWeek17 = async userId => {
    const database = await getDatabase();
    return database
        .collection('users')
        .doc(userId)
        .update({
            'planMilestones.week17ModalShown': true,
        });
};

export const updatePlanMilestoneWelcomeModal = async userId => {
    const database = await getDatabase();
    return database
        .collection('users')
        .doc(userId)
        .update({
            'planMilestones.welcomeModalShown': true,
        });
};

export const updateLastFollowupWeek = async (userId, week) => {
    const database = await getDatabase();
    return database
        .collection('users')
        .doc(userId)
        .update({
            'other.lastFollowupWeek': week,
        });
};

export const updateDiscordOnboarded = async userId => {
    const database = await getDatabase();
    return database
        .collection('users')
        .doc(userId)
        .update({
            'planMilestones.discordOnboarded': true,
        });
};


export const addReview = async (userId, subject, message) => {
    const database = await getDatabase();
    return database
        .collection('users')
        .doc(userId)
        .update({
            review: {
                subject,
                message,
            },
        });
};

export const getWorkoutPlans = userId => {
    return Observable.create(observer => {
        let listener;

        getDatabase$.subscribe(database => {
            listener = database
                .collection('users')
                .doc(userId)
                .collection('workout-plans')
                .onSnapshot(snapshot => {
                    const data: { [id: string]: any } = {};
                    snapshot.forEach(doc => {
                        const _doc = doc.data();
                        _doc.isPast = dayjs.utc(_doc.activeTo).isBefore(dayjs.utc());
                        data[doc.id] = _doc;
                    });
                    observer.next(data);
                });
        });

        return () => listener();
    });
};

export const getNextWorkoutPlan = async (userId, planId) => {
    const database = await getDatabase();
    return database
        .collection('users')
        .doc(userId)
        .collection('workout-plans')
        .doc(planId)
        .get()
        .then(snap => {
            const data = snap.data();
            if (data) {
                data.workouts = data.workouts.map(i => ({
                    ...i,
                    exercises: i.exercises.map(e => ({
                        ...e,
                        id: e.id || uuid(),
                    })),
                }));
                return data;
            }
            return {};
        });
};

export const updateUser = async (userId, updates) => {
    const database = await getDatabase();
    return database
        .collection('users')
        .doc(userId)
        .update(updates, { merge: true });
};

export const saveNextWorkoutPlan = async (userId, planId, update) => {
    const database = await getDatabase();
    return database
        .collection('users')
        .doc(userId)
        .collection('workout-plans')
        .doc(planId)
        .update(update);
};

export const getFirstWorkoutPlan = async (userId) => {
    const database = await getDatabase();
    const snapshots = await database.collection('users')
        .doc(userId)
        .collection('plans')
        .orderBy('activeFrom', 'asc')
        .limit(1)
        .get();

    const doc = snapshots.docs[0];
    const plan = doc.data();
    plan.id = doc.id;
    return plan;
}

export const subFavourites = (userId) => {
    return Observable.create(observer => {
        let listener;
        getDatabase$.subscribe(database => {
            listener = database
                .collection('users')
                .doc(userId)
                .collection('meta')
                .doc('favourites')
                .onSnapshot(snapshot => {
                    const data = snapshot.data();
                    observer.next(data);
                });
        });
        return () => listener();
    });
}

export const addToFavourite =async (userId, type, id) => {
    const database = await getDatabase();
    return database.collection('users')
        .doc(userId)
        .collection('meta')
        .doc('favourites')
        .set({
            [type]: firebase.firestore.FieldValue.arrayUnion(id),
        }, { merge: true });
}

export const removeFromFavourite =async (userId, type, id) => {
    const database = await getDatabase();
    return database.collection('users')
        .doc(userId)
        .collection('meta')
        .doc('favourites')
        .update({
            [type]: firebase.firestore.FieldValue.arrayRemove(id),
        });
}

export const subCurrentInvoice = (userId) => {
    return Observable.create(observer => {
        let listener;
        getDatabase$.subscribe(database => {
            listener = database
                .collection('users')
                .doc(userId)
                .collection('payments')
                .where('paid', '==', false)
                .limit(1)
                .onSnapshot(snapshot => {
                    const doc = snapshot.docs[0];
                    if (doc) {
                        const data = doc.data();
                        data.id = doc.id;
                        observer.next(data);
                    } else {
                        observer.next(null);
                    }
                });
        });
        return () => listener();
    });
}

export const getWorkoutPinnedNotes = async (userId) => {
    const database = await getDatabase();
    const snapshot = await database.collection('users')
        .doc(userId)
        .collection('meta')
        .doc('workout-pinned-notes')
        .get();

    return snapshot.data();
}

export const addWorkoutPinnedNote = async (userId, workoutId, note) => {
    const database = await getDatabase();
    return database.collection('users')
        .doc(userId)
        .collection('meta')
        .doc('workout-pinned-notes')
        .set({
            [workoutId]: note,
        }, { merge: true });
}

export const hasTermsAccepted = async (userId, version) => {
    const database = await getDatabase();
    const snapshot = await database.collection('users')
        .doc(userId)
        .collection('meta')
        .doc('terms')
        .get();

    const data = snapshot.data();
    return data && data[version];
}

export const acceptTerms = async (userId, version) => {
    const database = await getDatabase();
    return database.collection('users')
        .doc(userId)
        .collection('meta')
        .doc('terms')
        .set({
            [version]: new Date(),
        }, { merge: true });
}