import React, { createContext, useEffect, useContext, useState } from 'react';
import moment from 'moment-timezone';
import { UserContext } from './UserContext';
import { getWorkoutHistory } from '../apis/workout-plan';
import { Subscription } from 'rxjs';
import groupBy from 'lodash/groupBy';
import { getFirstPlanActiveFrom } from '../helpers/plan-helpers';
import { getWeekNumber } from '../helpers/date-helpers';
import { getIntlDate } from '../helpers/general';

export const WorkoutHistoryContext = createContext(null);

const WorkoutHistoryProvider = ({ children }) => {
    const { isLoaded, currentPlan, plans, user, profile } = useContext(UserContext);
    const [loaded, setLoaded] = useState(false);
    const [workoutHistory, setWorkoutHistory] = useState({});
    const [workoutHistoryGroupedByWeek, setWorkoutHistoryGroupedByWeek] = useState({});
    const [incompleteWorkout, setIncompleteWorkout] = useState(null);

    useEffect(() => {
        let workoutHistory$: Subscription;

        if (currentPlan.gym && !workoutHistory$) {
            workoutHistory$ = getWorkoutHistory(user.uid, currentPlan.planId).subscribe(data => {
                const newData = {
                    ...workoutHistory,
                    ...data,
                };

                if (!loaded) {
                    setLoaded(true);
                }
                const workoutKeys = Object.keys(newData);
                let newIncompleteWorkout = null;
                workoutKeys.some(workoutKey => {
                    const workoutData = newData[workoutKey];

                    if (
                        workoutData &&
                        (workoutData.duration === undefined ||
                            workoutData.duration.total === undefined)
                    ) {
                        newIncompleteWorkout = {
                            w: workoutData.workoutId,
                            id: workoutKey,
                        };
                        return true;
                    }

                    return false;
                });

                const firstPlanActiveFrom = getFirstPlanActiveFrom(plans);

                const workoutsGroupedByWeek = groupBy(newData as any, (workout: any) => {
                    if (workout && workout.date && firstPlanActiveFrom) {
                        const date = workout.date.toDate()
                        const newDate = getIntlDate(date, profile?.timeZone)
                        workout._date = newDate
                        const week = getWeekNumber(firstPlanActiveFrom.toDate(), newDate);
                        workout._week = week;
                        return week;
                    }

                    return null;
                });

                setWorkoutHistory(newData);
                setIncompleteWorkout(newIncompleteWorkout);
                setWorkoutHistoryGroupedByWeek(workoutsGroupedByWeek);
            });
        } else {
            setLoaded(true);
        }

        return () => {
            if (workoutHistory$) {
                workoutHistory$.unsubscribe();
            }
        };
    }, [isLoaded]);

    return (
        <WorkoutHistoryContext.Provider
            value={{
                loaded,
                workoutHistory,
                incompleteWorkout,
                workoutHistoryGroupedByWeek,
            }}
        >
            {children}
        </WorkoutHistoryContext.Provider>
    );
};

export default WorkoutHistoryProvider;
