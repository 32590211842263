import loadFirebase from '../firebase';
import { firebaseConfig } from '../config';

const authModule = async () => {
    const firebase = await loadFirebase();
    try {
        firebase.initializeApp(firebaseConfig);
    } catch (err) {
        // we skip the "already exists" message which is
        // not an actual error when we're hot-reloading
        if (!/already exists/.test(err.message)) {
            console.error('Firebase initialization error', err.stack);
        }
    }

    return firebase.auth();
};

export default authModule;