import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import * as Sentry from '@sentry/browser';
import UserProvider from '../contexts/UserContext';
import globalStyles from '../styles/global';
import WorkoutHistoryProvider from '../contexts/WorkoutHistoryContext';
import AddToHomeScreen from '../components/add-to-home';

Sentry.init({
    dsn: 'https://e47a427189f54abc8a2db0c58f3106a6@o394412.ingest.sentry.io/5244471',
    environment: process.env.STAGE,
    release: process.env.GITHUB_SHA,
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
        new Sentry.Integrations.Breadcrumbs({
            console: process.env.NODE_ENV === 'production',
        }),
    ],
});

export default class MyApp extends App {
    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });

        // This is needed to render errors correctly in development / production
        super.componentDidCatch(error, errorInfo);
    }

    createUrl = (router) => {
        // This is to make sure we don't references the router object at call time
        const { pathname, asPath, query } = router;
        return {
            get query() {
                return query;
            },
            get pathname() {
                return pathname;
            },
            get asPath() {
                return asPath;
            },
            back: () => {
                router.back();
            },
            push: (url, as) => router.push(url, as),
            pushTo: (href, as) => {
                const pushRoute = as ? href : null;
                const pushUrl = as || href;

                return router.push(pushRoute, pushUrl);
            },
            replace: (url, as) => router.replace(url, as),
            replaceTo: (href, as) => {
                const replaceRoute = as ? href : null;
                const replaceUrl = as || href;

                return router.replace(replaceRoute, replaceUrl);
            },
        };
    };

    onAddToHome = () => {
        alert('1. Open Share menu\n2. Tap on "Add to Home Screen" button');
    };

    render() {
        const { Component, pageProps, router } = this.props;
        const url = this.createUrl(router);
        return (
            <>
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
                    />
                </Head>
                <UserProvider>
                    <WorkoutHistoryProvider>
                        <Component {...pageProps} url={url} />
                        {/*<AddToHomeScreen*/}
                        {/*    title="Add JC Fitness to Home screen"*/}
                        {/*    onAddToHomescreenClick={this.onAddToHome}  />*/}
                        <style jsx global>
                            {globalStyles}
                        </style>
                    </WorkoutHistoryProvider>
                </UserProvider>
            </>
        );
    }
}
