import firebase from 'firebase/app';
import 'firebase/auth';


import { firebaseConfig } from '../config';

const firebaseApi = async () => {
    try {
        firebase.initializeApp(firebaseConfig);
    } catch (err) {
        // we skip the "already exists" message which is
        // not an actual error when we're hot-reloading
        if (!/already exists/.test(err.message)) {
            console.error('Firebase initialization error', err.stack);
        }
    }

    return firebase;
};

export default firebaseApi;