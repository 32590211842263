import loadFirebase from '../firebase';
import { firebaseConfig } from '../config';

const functions = async () => {
    const firebase = await loadFirebase();
    await import('firebase/functions');

    try {
        firebase.initializeApp(firebaseConfig);
    } catch (err) {
        // we skip the "already exists" message which is
        // not an actual error when we're hot-reloading
        if (!/already exists/.test(err.message)) {
            console.error('Firebase initialization error', err.stack);
        }
    }

    const functions = firebase.functions();
    try {
        // only connect to local firebase emulator if FB_EMULATOR is set to true
        if (process.env.FB_EMULATOR === 'true') {
            functions.useEmulator('localhost', 5001);
        }
    } catch (error) {}

    return functions;
};

export default functions;
