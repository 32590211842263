import { Observable } from 'rxjs';
const moment = require('moment-timezone');
import sum from 'lodash/sum';
import compact from 'lodash/compact';
import { getDatabase$, getDatabase } from './database';
import { WorkoutGroupMeta } from '../interfaces/workout-plan';
import dayjs from '../helpers/date-helpers';
import { roundTo } from '../helpers/general';

const uuid = () => (Math.random() * Math.random()).toString(36).substr(2);

export const getWorkoutGroupMeta = (groupMetaKey: string): Observable<WorkoutGroupMeta> =>
    Observable.create((observer) => {
        let groupMetaListener;
        getDatabase$.subscribe((database) => {
            groupMetaListener = database
                .collection('workout-group-meta')
                .doc(groupMetaKey)
                .onSnapshot((groupMeta) => {
                    const data = groupMeta.exists ? groupMeta.data() : null;
                    observer.next(data);
                    observer.complete();
                });
        });

        return () => groupMetaListener();
    });

export const getWorkoutGroupList$ = Observable.create((observer) => {
    let groupMetaListListener;

    getDatabase$.subscribe((database) => {
        groupMetaListListener = database
            .collection('workout-group-meta')
            .onSnapshot((groupMetaListData) => {
                if (groupMetaListData.empty) {
                    observer.next({});
                } else {
                    const mappedGroupMetaList = {};
                    groupMetaListData.forEach((groupMeta) => {
                        mappedGroupMetaList[groupMeta.id] = groupMeta.data();
                    });
                    observer.next(mappedGroupMetaList);
                }
            });
    });

    return () => groupMetaListListener();
});

export const getWorkoutPlan = async (plan) => {
    if (!plan) return {};
    const database = await getDatabase();
    const orderedKeys = [
        'upper',
        'Upper',
        'push',
        'Push',
        'lower',
        'Lower',
        'pull',
        'Pull',
        'full',
        'Full',
        'legs',
        'Legs',
    ];

    const workoutPlan = await database.collection('workout-plans').doc(plan).get();

    const data = workoutPlan.data();
    if (data) {
        Object.values(data).forEach((workouts: any[]) => {
            workouts.forEach((wo) => {
                if (wo.exercise && !wo.id) {
                    wo.id = uuid();
                }
            });
        });
        data.notes = compact(data.notes);
        if (!data.notes.length) {
            orderedKeys.forEach((key) => {
                if (data[key]) {
                    data.notes.push(key);
                }
            });
        }
        return data;
    }
    return {};
};

export const getWorkoutTimes = async (plan) => {
    const database = await getDatabase();

    if (!plan) return {};

    const workoutTimes = await database.collection('workout-plan-times').doc(plan).get();

    return workoutTimes.exists ? workoutTimes.data() : {};
};

export const getPreviousExerciseSessionList = async (uid, planId) => {
    const database = await getDatabase();

    const workoutSessionList = await database
        .collection('users')
        .doc(uid)
        .collection('plans')
        .doc(planId)
        .collection('exercise-logs')
        .get();

    if (workoutSessionList.empty) {
        return {};
    }

    const mappedWorkoutSessionList = {};
    workoutSessionList.forEach((sessionMeta) => {
        mappedWorkoutSessionList[sessionMeta.id] = sessionMeta.data();
    });
    return mappedWorkoutSessionList;
};

export const getAllPreviousExerciseSessionList = async (uid) => {
    const database = await getDatabase();
    const snapshots = await database.collection('users').doc(uid).collection('plans').get();
    const mappedWorkoutSessionList = {};

    for (let index = 0; index < snapshots.docs.length; index++) {
        const plan = snapshots.docs[index];
        const planId = plan.id;
        const workoutSessionList = await database
            .collection('users')
            .doc(uid)
            .collection('plans')
            .doc(planId)
            .collection('exercise-logs')
            .get();

        workoutSessionList.forEach((sessionMeta) => {
            mappedWorkoutSessionList[sessionMeta.id] = {
                ...(mappedWorkoutSessionList[sessionMeta.id] || {}),
                ...(sessionMeta.data() || {}),
            };
        });
    }

    return mappedWorkoutSessionList;
};

export const getHomeTrainingLogsList = async (uid, planId) => {
    const database = await getDatabase();

    const homeTrainingLogsList = await database
        .collection('users')
        .doc(uid)
        .collection('plans')
        .doc(planId)
        .collection('home-training-log')
        .get();

    if (homeTrainingLogsList.empty) {
        return {};
    }

    const mappedHomeTrainingLogsList = {};
    homeTrainingLogsList.forEach((sessionMeta) => {
        mappedHomeTrainingLogsList[sessionMeta.id] = sessionMeta.data();
    });

    return mappedHomeTrainingLogsList;
};

export const getPreviousExerciseSessions = async (uid, plans, exerciseName) => {
    const database = await getDatabase();
    const allData = await Promise.all(
        plans.map((planId) => {
            return database
                .collection('users')
                .doc(uid)
                .collection('plans')
                .doc(planId)
                .collection('exercise-logs')
                .doc(exerciseName)
                .get()
                .then((snap) => {
                    if (snap.exists) {
                        const data = snap.data();
                        Object.values(data).forEach((wo: any) => {
                            wo.planId = planId;
                        });
                        return data;
                    }
                });
        })
    );
    return allData.reduce((acc, data: any) => {
        if (data) {
            return Object.assign(acc, data);
        }
        return acc;
    }, {});
};

export const getWorkoutHistory = (userId: string, planId: string): Observable<WorkoutGroupMeta> =>
    Observable.create((observer) => {
        let workoutHistoryListener;

        getDatabase$.subscribe((database) => {
            workoutHistoryListener = database
                .collection('users')
                .doc(userId)
                .collection('plans')
                .doc(planId)
                .collection('training-log')
                .onSnapshot((workoutHistory) => {
                    let data = {};

                    if (!workoutHistory.empty) {
                        workoutHistory.forEach((doc) => {
                            data[doc.id] = {
                                id: doc.id,
                                ...doc.data(),
                            };
                        });
                    }

                    observer.next(data);
                });
        });

        return () => workoutHistoryListener();
    });

export const setWorkoutGroup = async (groupNum, groupMeta, workoutData) => {
    const database = await getDatabase();

    const metaRequest = database
        .collection('workout-group-meta')
        .doc(`group-${groupNum}`)
        .set(groupMeta, { merge: true });

    const workoutBatch = database.batch();

    Object.keys(workoutData).forEach((planKey) => {
        const ref = database.collection('workout-plans').doc(planKey);
        workoutBatch.set(ref, workoutData[planKey]);
    });

    const workoutRequest = workoutBatch.commit();

    return Promise.all([metaRequest, workoutRequest]);
};

export const updateWorkoutPlanTemplate = async (id, data) => {
    const database = await getDatabase();
    return database.collection('workout-plans').doc(id).set(data, { merge: false });
};

export const getAverageWeightFromPreviousPeriod = async (uid: string, prevPlanId?: string) => {
    const database = await getDatabase();
    const logs = await database
        .collection(`/users/${uid}/meta`)
        .doc('weight-log')
        .get()
        .then((s) => s.data());
    if (logs) {
        console.log(logs);
        let weekDate;
        const prevEndDate = prevPlanId ? prevPlanId.split('_')[1] : null;
        if (prevEndDate) {
            weekDate = moment.utc(prevEndDate).endOf('isoWeek');
        } else {
            weekDate = moment.utc().subtract(1, 'week').endOf('isoWeek');
        }

        const filteredWeightLogs = Object.keys(logs)
            .filter((logKey) => moment.utc(logKey).isSame(weekDate, 'isoWeek'))
            .map((logKey) => {
                return logs[logKey];
            });

        const averageWeeklyWeight = roundTo(sum(filteredWeightLogs) / filteredWeightLogs.length, 1);
        return averageWeeklyWeight;
    }
};
